import React, { useMemo } from "react";
import { useTable } from "react-table";
import Loader from "./loader/loader";
import '../HojasDeEstilo/Tabla.css';

const TablaMedicion = ({ columns, data, isLoading, manualPagination = false, cambiarComponente, cambiarComponente2 }) => {
    const columnData = useMemo(() => columns, [columns]);
    const rowData = useMemo(() => data, [data]);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns: columnData,
        data: rowData,
        manualPagination,
    });
    return (
        <>
            <>
                <table {...getTableProps()} className="tablaGC">
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr  {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th  {...column.getHeaderProps()}
                                        style={{ width: column.render("width") }}>
                                        {column.render("Header")}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td {...cell.getCellProps()}>{cell.render("Cell")} </td>

                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>

            </>

        </>
    );
};

export default TablaMedicion;
