import fileDefault from '../../images/file-blank-solid-240.png';
import fileCSS from '../../images/file-css-solid-240.png';
import filePdf from '../../images/file-pdf-solid-240.png';
import filePng from '../../images/file-png-solid-240.png';
import fileJpg from "../../images/JPG_29711.png";
import fileDoc from "../../images/word-logo-8.png";
import fileExcel from "../../images/Microsoft_Office_Excel_(2019–present).svg.png";
import filePower from "../../images/microsoft-powerpoint-logo-0.png"

export const ImageConfig = {
    default: fileDefault,
    pdf: filePdf,
    png: filePng,
    css: fileCSS,
    jpeg: fileJpg,
    "vnd.openxmlformats-officedocument.wordprocessingml.document": fileDoc,
    "vnd.openxmlformats-officedocument.spreadsheetml.sheet": fileExcel,
    "vnd.openxmlformats-officedocument.presentationml.presentation": filePower,
}